<template>
    <div>
        <b-row>
            <b-col cols="12" md="6">
                    <b-form-group :label="$t('academic_year')">
                        <b-input-group><div class="label-as-input">{{ isNotNullValue(formData.academic_year) }}</div></b-input-group>
                    </b-form-group>
            </b-col>
            <b-col cols="12" md="6">

                    <b-form-group :label="$t('code')">
                        <b-input-group><div class="label-as-input">{{ isNotNullValue(formData.code) }}</div></b-input-group>
                    </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                    <b-form-group :label="$t('date')">
                        <b-input-group ><div class="label-as-input">{{ isNotNullValue(formData.date) }}</div></b-input-group>
                    </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    // Services
    import ProficiencyService from '@/services/ProficiencyService';

    export default {
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formData: {},
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                ProficiencyService.get(id)
                              .then(response => {
                                  this.formData = response.data.data;
                              })
                              .catch(e => {
                                  this.showErrors(e)
                              })
            }
        }
    }
</script>

<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col sm="6" md="4" lg="3" xl="6">
                    <ValidationProvider name="academic_year" rules="required" v-slot="{valid,errors}">
                    <b-form-group :label="$t('academic_year')">
                        <academic-years-selectbox v-model="formData.academic_year"  :validate-error="errors[0]"></academic-years-selectbox>
                    </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col sm="6" md="4" lg="3" xl="6">
                    <ValidationProvider name="code" rules="required" v-slot="{valid,errors}">
                    <b-form-group :label="$t('code')">
                        <b-form-input v-model="formData.code">
                        </b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                    </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col sm="6" md="4" lg="3" xl="6">
                    <b-form-group :label="$t('date')">
                        <select-date v-model="formData.date" />
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm" :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Services
import ProficiencyService from '@/services/ProficiencyService';

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
    components: {
        AcademicYearsSelectbox: () => import('@/components/interactive-fields/AcademicYearsSelectbox'),
        SelectDate: () => import("@/components/interactive-fields/SelectDate"),
        ValidationProvider,
        ValidationObserver
    },
    props: {
        formId: {
            type: String
        }
    },
    data() {
        return {
            formLoading: false,
            formData: {
                academic_year: null,
                code: null,
                date: null
            },
            deanObject: {}
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            ProficiencyService.get(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData = data
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },

        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formLoading = true;
                ProficiencyService.update(this.formId, this.formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>

<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col sm="6" md="4" lg="3" xl="6">
                    <ValidationProvider name="academic_year" rules="required" v-slot="{valid,errors}">
                    <b-form-group :label="$t('academic_year')">
                        <academic-years-selectbox v-model="formData.academic_year"  :validate-error="errors[0]"></academic-years-selectbox>
                    </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col sm="6" md="4" lg="3" xl="6">
                    <ValidationProvider name="code" rules="required" v-slot="{valid,errors}">
                    <b-form-group :label="$t('code')">
                        <b-form-input v-model="formData.code">
                        </b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                    </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col sm="6" md="4" lg="3" xl="6">
                    <ValidationProvider name="date" rules="required" v-slot="{valid,errors}">
                    <b-form-group :label="$t('date')">
                        <select-date v-model="formData.date" />
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                    </b-form-group>
                </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Services
    import ProficiencyService from '@/services/ProficiencyService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            AcademicYearsSelectbox: () => import('@/components/interactive-fields/AcademicYearsSelectbox'),
            SelectDate: () => import("@/components/interactive-fields/SelectDate"),
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                formLoading: false,
                formData: {
                }
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    ProficiencyService.store(this.formData)
                                  .then(response => {
                                      this.$toast.success(this.$t('api.' + response.data.message));
                                      this.$emit('createFormSuccess', true);
                                  })
                                  .catch(e => {
                                      this.showErrors(e, this.$refs.formModalValidate);
                                  })
                                  .finally(() => {
                                      this.formLoading = false
                                  })

                }
            }
        }
    }
</script>


<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('proficiency_exams')"
                        :isNewButton="checkPermission('proficiencyexam_store')"
                        @new-button-click="createFormShow()"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('faculties')"
                              :isNewButton="checkPermission('proficiencyexam_store')"
                              :isColumns="true"
                              @new-button-click="createFormShow()"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="6">
                        <b-form-group :label="$t('name')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.academic_year"
                            ></academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="6">
                        <b-form-group :label="$t('code')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.code">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="6">
                        <b-form-group :label="$t('date')">
                            <select-date v-model="datatable.queryParams.filter.date"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>

            <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="checkPermission('proficiencyexam_show')">
                <template v-slot:CommonModalTitle>
                    {{ $t('show') }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>

            <CommonModal ref="createFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('proficiencyexam_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new') }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>

            <CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('proficiencyexam_update')">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit') }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    // Pages
    import ShowForm from './ShowForm';
    import CreateForm from './CreateForm';
    import UpdateForm from './UpdateForm';

    // Services
    import ProficiencyService from '@/services/ProficiencyService';

    // Others
    import qs from 'qs'
    export default {
        components: {
            AcademicYearsSelectbox: () => import('@/components/interactive-fields/AcademicYearsSelectbox'),
            SelectDate: () => import("@/components/interactive-fields/SelectDate"),
            ShowForm,
            CreateForm,
            UpdateForm,
        },
        metaInfo() {
            return {
                title: this.$t('faculties')
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('show'),
                                    class: 'ri-search-eye-line',
                                    permission: 'proficiencyexam_show',
                                    callback: (row) => {
                                        this.showForm(row.id);
                                    },
                                   
                                },
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: 'proficiencyexam_update',
                                    callback: (row) => {
                                        this.updateFormShow(row.id)
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: 'proficiencyexam_delete',
                                    callback: (row) => {
                                        this.formDelete(row.id)
                                    }
                                }
                            ]
                        },
                        {
                            label: this.toUpperCase('academic_year'),
                            field: 'academic_year',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('code'),
                            field: 'code',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('date'),
                            field: 'date',
                            hidden: false
                        }

                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'code',
                        page: 1,
                        limit: 20
                    }
                },
                formProcess: null,
                formId: null,
                form: {}
            }
        },
        mounted() {
            this.filterSet();
            this.formClear();
        },
        methods: {
            filterSet() {
                this.datatable.queryParams.filter = {
        
                }
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key) {
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return ProficiencyService.getAll(config)
                                     .then((response) => {
                                         this.datatable.rows = response.data.data
                                         this.datatable.total = response.data.pagination.total
                                     })
                                     .finally(() => {
                                         this.datatable.isLoading = false;
                                     });
            },

            // Clear
            formClear() {
                this.formId = null
                this.formProcess = null
            },

            // Show
            showForm(id) {
                this.formId = id
                this.formProcess = 'show'
                this.$refs.showModal.$refs.commonModal.show()
            },

            // Create
            createFormShow() {
                this.formId = null
                this.formProcess = 'create'
                this.$refs.createFormModal.$refs.commonModal.show()
            },
            createFormSuccess() {
                this.$refs.createFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },

            // Update
            updateFormShow(id) {
                this.formId = id
                this.formProcess = 'update'
                this.$refs.updateFormModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.$refs.updateFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },

            formDelete(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            ProficiencyService.del(id)
                                          .then(response => {
                                              this.$toast.success(this.$t('api.' + response.data.message));
                                              this.getRows();
                                              this.formClear();
                                          })
                                          .catch(error => {
                                              this.$toast.error(this.$t('api.' + error.data.message));
                                          });
                        }
                    })
            },
        }
    }
</script>

